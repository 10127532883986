import { Button, message, Modal } from 'antd';
import { verifyMessage } from 'ethers/lib/utils.js';
import React, { useEffect, useState } from 'react'
import { useSignMessage } from 'wagmi';

function SignModal({title, body, fields, open, setOpen, onSubmit}) {
    const [signatureCompleted, setSignatureCompleted] = useState({})
    const {data, isError, signMessage, error , isSuccess} = useSignMessage(
        {onSuccess(data, variables) {
            // Verify signature when sign message succeeds
            const addr = verifyMessage(variables.message, data)
            setSignatureCompleted({address: addr, message:variables.message, signature: data })
        },}
    )
    const [canceled, setCanceled] = useState(false)

    const handleCancel = () => {
       setCanceled(true)
       setOpen(false)
    };

    const handleSignature = () => {
        if(!fields) {
            return
        }
        const mess = JSON.stringify(fields)
        if(mess.legth < 5) {
            return
        }
        signMessage({message:mess})
        setCanceled(false)
     };

     useEffect(() => {
        if (error?.message) {
            if (error.name === "ConnectorNotFoundError") {
                message.error("conecte su billetera para firmar el mensaje")
                return
            }
            message.error("error firmando el mensaje con la billetera")
        }
        
     }, [error] )
  return (
    <Modal open={open} title={title} onCancel={handleCancel}
    footer={[
        <Button key="back" onClick={handleCancel}>
          Cancelar
        </Button>,
        <Button key="link" type="primary" onClick={handleSignature}>
          Firmar
        </Button>,
        <Button key="submit" type="primary" onClick={() => onSubmit(signatureCompleted)} disabled={!isSuccess || canceled} >
         Publicar
        </Button>
    ]}
    >
       {body}
    </Modal>
  )
}

export default SignModal