import { Typography } from 'antd'
import React from 'react'
import { useParams } from 'react-router-dom'
import DeleteTable from '../../components/DeleteTable/DeleteTable'
import IsLoadingMessage from '../../components/ErrorDisplay/IsLoading'
import IsLoading from '../../components/ErrorDisplay/IsLoading'
import LoadingError from '../../components/ErrorDisplay/LoadingError'
import { useCategorizationInCategoryQuery } from '../../services/ensesp-api'

function DeleteInCategory() {
    const {categoryID} = useParams()
    const {data: domains, isLoading, isError , isSuccess} = useCategorizationInCategoryQuery({"category":categoryID})
    if (isLoading) {
        return <IsLoadingMessage text={"Estamos cargando las categorizaciones"}></IsLoadingMessage>
    }
    if (isError) {
       return  <LoadingError text={"Ha ocurrido un error "}></LoadingError>
    }
    if(isSuccess && (!domains || domains.length <1 )) {
        return <LoadingError text={"No se encontraron resultados para mostrar"}></LoadingError>
    }

    const result = domains?.map(element => {
        const date = new Date(element.updated_at)
        const options = {
           year: 'numeric', month: 'numeric', day: 'numeric',hour: 'numeric', minute: 'numeric',
        };
        const dateString = new Intl.DateTimeFormat('es-AR', options).format(date)
        return {
          "domain" : element.domain,
          "requested_by" : element.requested_by,
          "authorized_by" : element.authorized_by,
          "status": element.status,
          "updatedAtString" : dateString,
          "updatedAt": date,
          "reason": element.reason
        }
      })

    return (
      <>
        <Typography.Title level={4}>{`Encontramos estas categorizaciones en ${categoryID}`}</Typography.Title>
        <DeleteTable domains={result} category={categoryID}/>
      </>
    )
}

export default DeleteInCategory