import {  Typography } from 'antd';
import React from 'react'
const { Text} = Typography;

function LoadingError({text}) {
  return (
    <Text type="danger" style={{"color":"white", "paddingTop": "10px"}}>{text}</Text>
  )
}

export default LoadingError