import { Space, Typography } from 'antd';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import SelectCategory from '../../components/SelectCategory/SelectCategory';

function SelectDeleteCategory() {
  const navigate = useNavigate();
 
  const onSelect = (value) => {
     navigate(`/category/delete/${value}`)
  };
  
  return (
    <>
     
     <Space direction='vertical'>
     <Typography.Text>Selecciona la categoría en la que vas a eliminar </Typography.Text>
     <SelectCategory onSelect={onSelect}/>
     </Space>
     
    </>
    
  )
}

export default SelectDeleteCategory