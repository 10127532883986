import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import store from './app/store';
import { BrowserRouter as Router} from 'react-router-dom';
import {Provider} from 'react-redux';
import { ConfigProvider, theme } from 'antd';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ConfigProvider
      theme={{
       algorithm: theme.darkAlgorithm,
      }}
      
      
     >
  <Provider store={store}>
  <Router>
    <App />
  </Router>
  </Provider>
  </ConfigProvider>
);


