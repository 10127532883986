import { Button, Space, Table } from 'antd';
import React from 'react'
import { useGetAdminsQuery } from '../../services/ensesp-api'
import IsLoading from '../ErrorDisplay/IsLoading';
import LoadingError from '../ErrorDisplay/LoadingError';

const columns = [
    {
      title: 'Administrador',
      dataIndex: 'admin_id',
      key: 'admin_id',
      sorter: (a, b) => a.admin_id.localeCompare(b.admin_id),
    },
    {
      title: 'Dirección ETH',
      dataIndex: 'address',
      key: 'address',
      sorter:  (a, b) => a.address.localeCompare(b.address),
    },
    {
      title: 'Autorizado por',
      dataIndex: 'authorized_by',
      key: 'authorized_by',
      sorter: (a, b) => a.authorized_by.localeCompare(b.authorized_by),
    },
    {
        title: 'Acciones',
        key: 'action',
        render: (_, record) => (
          <Space size="middle">
            <a href={`https://etherscan.io/address/${record.address}`} target={"_blank"}>Ver en etherscan</a>
          </Space>
        ),
      },
  ];

  
function Admin() {
    const {data: admins, isLoading, isError , isSuccess} = useGetAdminsQuery()
    if (isLoading) {
        return <IsLoading text={"Estamos cargando los administradores"}></IsLoading>
    }
    if (isError) {
       return  <LoadingError text={"Ha ocurrido un error "}></LoadingError>
    }
    if(isSuccess && (!admins || admins.length <1 )) {
        return <LoadingError text={"No se encontraron resultados para mostrar"}></LoadingError>
    }
  return (
    <>
        <Table dataSource={admins} columns={columns} showSorterTooltip={false} scroll={{x: true}}/>
    </>
    
  )
}

export default Admin