import { Col, Divider, Row, Space, Typography } from 'antd'
import React from 'react'
import CategoriesTable from '../../components/CategoriesTable/CategoriesTable'
import EditCategoryFrom from '../../components/EditCategoryForm/EditCategoryFrom'

function EditCategory() {

  return (
    <>
     <Row >
     <Col xs={0} xl={3}>
     </Col>
      
        <Col xs={24} xl={10}>
          <Space direction="vertical">
          <Typography.Title level={3} style={{textAlign: "center", "marginBottom": "30px"}} >Crea o actualiza una categoría:</Typography.Title>
          <EditCategoryFrom />
          </Space>
      
       </Col>
      
       <Col xs={24} xl={10}>
      <div>
      <Typography.Text > Categorías registradas:</Typography.Text>
      <CategoriesTable short={true}/>
      </div>
      </Col>
      <Col xs={0} xl={1}>   </Col>
      </Row>
     

     
      
     </>
  )
}

export default EditCategory