import { AutoComplete, Button, Form, Input, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useCategoriesQuery, useCreateAdminMutation, useCreateCategoryMutation } from '../../services/ensesp-api';
import CategoriesTable from '../CategoriesTable/CategoriesTable';
import SignModal from '../Signature/SignModal';
const { TextArea } = Input;

function AddAdminForm() {
    const {data: categories, isLoading, isError} = useCategoriesQuery();
    const [postModeration, result, error  ] = useCreateAdminMutation()

    const [form] = Form.useForm();
    const [modal, setModal] = useState(false)
    const [fieldsToSign, setFieldsToSign] = useState({})

    useEffect(() => {
        console.log(result)
        if (result.isError) {
          message.error("Ha ocurrido un error sometiendo la moderación")
          return
        }
        if (result.isSuccess) {
          message.success("Solicitud completada exitosamente")
          setModal(false)
          setTimeout(() => window.location.reload(false), 1000);
          return
        }
      
    
      
        
      }, [result])
    

    

      const onReset = () => {
        form.resetFields();
      };

      function onSubmit({address, signature}) {
        postModeration({"address": address, "signature": signature, "body": fieldsToSign})
      }

      const onConfirm = () => {
        const id = form.getFieldValue("field-id") 
        const address = form.getFieldValue("field-address") 
    
        if(!id) {

           message.warning("Debe especificar un alias")
            return
        }
        if(!address) {

          message.warning("Debe especificar una dirección")
           return
       }
        const queryObj = {
            id:id,
            address:address 
        }
        setFieldsToSign(queryObj)
        setModal(true)

      };

  return (
    <>
    <Form
    form={form}
    size="default"
    layout="vertical"
    
    >
        <Form.Item label="Alias del administrador" name={`field-id`}>
            <Input placeholder='preferiblemmente ens username'  style={{width:300}}/>
        </Form.Item>
        <Form.Item label="Dirección Ethereum del administrador"  name={`field-address`}>
            <Input placeholder='será utilizada para autorizar las operaciones ' style={{width:320}}/>
        </Form.Item>
         <Form.Item>
         <Button type='reset' style={{margin:10,marginLeft:50 }} onClick={onReset}>
            Limpiar
        </Button>
        <Button type='primary'  onClick={onConfirm} htmlType="submit">
            Confirmar
        </Button>
        </Form.Item>

    </Form>
    
    <SignModal onSubmit={onSubmit} title={`Confirma el nuevo administrador`} open={modal} setOpen={setModal} fields={fieldsToSign} body={<div>Atención, estás otorgando nuevos roles de administración, esto incluye la posibilidad de aprobar/rechazar y eliminar categorizaciones.</div>}/>
    </>
 
  )
}

export default AddAdminForm