import { Space, Table } from 'antd';
import React from 'react'

const columns = [
    {
      title: 'Dominio',
      dataIndex: 'domain',
      key: 'domain',
      sorter: (a, b) => a.domain.localeCompare(b.domain),
    },
    {
      title: 'Categoría',
      dataIndex: 'category',
      key: 'category',
      sorter: (a, b) => a.category.localeCompare(b.category),
    },
    {
        title: 'Estado',
        dataIndex: 'status',
        key: 'status',
        sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: 'Solicitado por',
      dataIndex: 'requested_by',
      key: 'requested_by',
      sorter: (a, b) => a.requested_by.localeCompare(b.requested_by),

      
    },
    {
      title: 'Última actualización',
      dataIndex: 'updatedAtString',
      key: 'updatedAtString',
      sorter: (a, b) => a.updatedAt > b.updatedAt,
    },
    {
        title: 'Autorizado por',
        dataIndex: 'authorized_by',
        key: 'authorized_by',
    },
    {
        title: 'Motivo de moderación',
        dataIndex: 'reason',
        key: 'reason',
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
      <Space size="middle">
        <a href={`https://www.ensesp.io/domain/${record.domain}.eth`} target="_blank">Ver en ensesp.io</a>
     </Space>
        
      ),
    },
  ];

function LastDomainTable({domains}) {
  return (
    <Table columns={columns} dataSource={domains} showSorterTooltip={false} scroll={{x: true}}/>
  )
}

export default LastDomainTable