import { Button, Input, message, Modal, Space, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react'

import { useApproveCategorizationBatchMutation, usePendingCategorizationQuery } from '../../services/ensesp-api';
import IsLoadingMessage from '../ErrorDisplay/IsLoading';
import LoadingError from '../ErrorDisplay/LoadingError';
import SignModal from '../Signature/SignModal';




function DomainsTable({category}) {
  const {data: categories, isLoading, isError , isSuccess} = usePendingCategorizationQuery(category)
  const [fieldsToSign, setFieldsToSign] = useState([])
  const [action, setAction] = useState('')
  const [value, setValue] = useState("")
  const [modal, setModal] = useState(false)
  const [postModeration, result, error  ] = useApproveCategorizationBatchMutation()
  

  useEffect(() => {
    console.log(result)
    if (result.isError) {
      message.error("Ha ocurrido un error sometiendo la moderación")
      return
    }
    if (result.isSuccess) {
      message.success("Solicitud completada exitosamente")
      setTimeout(() => window.location.reload(false), 1000);
      setOpen(false)
      return
    }
  

  
    
  }, [result])


  if (isLoading) {
    return <IsLoadingMessage text={"cargando categorizaciones..."}></IsLoadingMessage>
  }

  function onSubmit({address, signature}) {
    postModeration({"approve": action, "address": address, "signature": signature, "body": fieldsToSign})
  }


  function setOpen(val) {
    setModal(val)
    setValue("")
  }

  
  


  if (isError) {
    return <LoadingError text={"Ha ocurrido un error cargando las categorías"}/>
  }

  if (isSuccess && (!categories || categories.length < 1)) {
    return <LoadingError text={"No existen categorizaciones pendientes para mostrar"}/>
  }
  const data = categories?.map(element => {
    const date = new Date(element.UpdatedAt)
    const options = {
       year: 'numeric', month: 'numeric', day: 'numeric',hour: 'numeric', minute: 'numeric',
    };
    const dateString = new Intl.DateTimeFormat('es-AR', options).format(date)
    return {
      "domain" : element.Domain,
      "category" : element.Category,
      "requestedBy" : element.RequestedBy,
      "updatedAtString" : dateString,
      "updatedAt": date,
    }
  })

  const handleChange = (event) => {
    setValue(event.target.value )
    const reas = event.target.value && event.target.value.length > 3 ? event.target.value: ''
    const fields = fieldsToSign 
    if (!fields || fields.length != 1 || !fields[0].category || !fields[0].domain) {
      return 
    }
    setFieldsToSign([{"category": fields[0].category, "domain": fields[0].domain, "reason": reas}])
  };

  

  function buildApproveFields(record) {
    setFieldsToSign([{"category": record.category, "domain": record.domain, "reason": ""}])
    setAction("approve")
    setModal(true)
  }

  function buildRejectFields(record) {
    setFieldsToSign([{"category": record.category, "domain": record.domain, "reason": ""}])
    setAction("reject")
    setModal(true)
  }

  const columns = [
    {
      title: 'Dominio',
      dataIndex: 'domain',
      key: 'domain',
      sorter: (a, b) => a.domain.localeCompare(b.domain),
    },
    {
      title: 'Categoría',
      dataIndex: 'category',
      key: 'category',
      sorter: (a, b) => a.category.localeCompare(b.category),
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          
          <Button   onClick={() => buildApproveFields(record)}>Aprobar</Button>
          <Button danger  onClick={() => buildRejectFields(record)}>Rechazar</Button>
          <a href={`https://www.ensesp.io/domain/${record.domain}.eth`} target="_blank">Ver en ensesp.io</a>
        </Space>
        
      ),
    },
    {
      title: 'Solicitado por',
      dataIndex: 'requestedBy',
      key: 'requestedBy',
      sorter: (a, b) => a.requestedBy.localeCompare(b.requestedBy)
    },
    {
      title: 'Última actualización',
      dataIndex: 'updatedAtString',
      key: 'updatedAtString',
      sorter: (a, b) => a.updatedAt > b.updatedAt,
    },
    
  ];

  const formBody = 
  <div style={{paddingTop: "5px", paddingBottom: "5px"}}>
      <Typography.Text >Justifica tu moderación: </Typography.Text>
      <Input placeholder='Explica brevemente por qué tomaste tu decisión' value={value} onChange={handleChange} />
      
  </div>


  return (
    <>
  <Table columns={columns} dataSource={data} showSorterTooltip={false } scroll={{x: true}}>

  </Table>
    <SignModal title={"Autoriza la operación"}  open={modal} setOpen={setOpen} fields={fieldsToSign} body={formBody} onSubmit={onSubmit} scroll={{x: true}}/>
  </>

  )
}

export default DomainsTable