import React from 'react'
import { Select, Space, Typography } from 'antd';
import IsLoading from '../../components/ErrorDisplay/IsLoading';
import LoadingError from '../../components/ErrorDisplay/LoadingError';
import { useCategoriesQuery } from '../../services/ensesp-api';
import { useNavigate } from 'react-router-dom';
import SelectCategory from '../../components/SelectCategory/SelectCategory';

function SelectPendingCategory() {
  const navigate = useNavigate();
 
  const onSelect = (value) => {
     navigate(`/pending/${value}`)
  };
  
  return (
    <>
     
     <Space direction='vertical'>
     <Typography.Text>Consulta las categorizaciones pendientes en una categoría individual: </Typography.Text>
     <SelectCategory onSelect={onSelect}/>
     </Space>
     
    </>
    
  )
}

export default SelectPendingCategory