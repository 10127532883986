import {  Typography } from 'antd';
import React from 'react'
const { Text} = Typography;

function IsLoadingMessage({text}) {
  return (
    <Text style={{"color":"white", "paddingTop": "10px"}}>{text}</Text>
  )
}

export default IsLoadingMessage