import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultWallets,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { Layout, Menu } from 'antd';

import {
  configureChains,
  createClient,
  WagmiConfig,
} from 'wagmi';
import { mainnet } from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';

import CategoriesTable from "./components/CategoriesTable/CategoriesTable";
import DomainsTable from "./components/DomainsTable/DomainsTable";
import { useState } from 'react';
import AppLayout from './components/AppLayout/AppLayout';



const { chains, provider } = configureChains(
  [mainnet],
  [
    alchemyProvider({ apiKey: "FxkCI6WVT4GWQVr43KflH9fYlgIjc6Cn" }),
    publicProvider()
  ]
);
const { connectors } = getDefaultWallets({
  appName: 'ENS Español',
  chains
});
const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
})


function App() {
  return (
    <>
       <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider modalSize="compact" chains={chains}>
        <AppLayout/>
        
       
      </RainbowKitProvider>
      </WagmiConfig>
    </>
  );
}

export default App;
