import { Typography } from 'antd'
import React from 'react'
import CategoriesTable from '../../components/CategoriesTable/CategoriesTable'

function AllCategories() {
  return (
    <>
        <Typography.Title level={4}>Explora todas las categorías</Typography.Title>
        <CategoriesTable />
    </>
  )
}

export default AllCategories