import { Space, Table } from 'antd';
import React from 'react'
import { Link } from 'react-router-dom';
import { useCategoriesQuery } from '../../services/ensesp-api';
import IsLoadingMessage from '../ErrorDisplay/IsLoading';
import LoadingError from '../ErrorDisplay/LoadingError';

const columns = [
  {
    title: 'Categoría',
    dataIndex: 'category',
    key: 'category',
    sorter: (a, b) => a.category.localeCompare(b.category),
  },
  {
    title: 'Puntos',
    dataIndex: 'points',
    key: 'points',
    sorter: (a, b) => a.points - b.points,
  },
  {
    title: 'Short',
    dataIndex: 'short',
    key: 'short',
    sorter: (a, b) => a.short.localeCompare(b.short),
  },
  {
    title: 'Grupo',
    dataIndex: 'group',
    key: 'group',
    sorter: (a, b) => a.group.localeCompare(b.group),
  },
  {
    title: 'Dominios',
    dataIndex: 'domains',
    key: 'domains',
    sorter: (a, b) => a.domains  - b.domains
  },
  {
    title: 'Autorizado por',
    dataIndex: 'authorized_by',
    key: 'authorized_by',
    sorter: (a, b) => a.authorized_by.localeCompare(b.authorized_by),
  },
  {
      title: 'Acciones',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Link to={`/pending/${record.category}`}>Ver pendientes</Link>
          <a href={`https://www.ensesp.io/category/${record.category}`} target={"_blank"}>Ver en ensesp.io</a>
        </Space>
      ),
    },
];

  const columShort = [
    {
      title: 'Categoría',
      dataIndex: 'category',
      key: 'category',
      sorter: (a, b) => a.category.localeCompare(b.category),
    },
    {
      title: 'Puntos',
      dataIndex: 'points',
      key: 'points',
      sorter: (a, b) => a.points - b.points,
    },
    {
      title: 'Short',
      dataIndex: 'short',
      key: 'short',
      sorter: (a, b) => a.short.localeCompare(b.short),
    },
    {
      title: 'Grupo',
      dataIndex: 'group',
      key: 'group',
      sorter: (a, b) => a.group.localeCompare(b.group),
    },
    {
      title: 'Dominios',
      dataIndex: 'domains',
      key: 'domains',
      sorter: (a, b) => a.domains  - b.domains,
    },
  ]


function CategoriesTable({short=false}) {
  const {data: categories, isLoading, isError} = useCategoriesQuery();

  if (isLoading) {
    return <IsLoadingMessage text="Cargando categorías..."/>
    
  }
  if (isError || !categories || categories.length < 1) {
    return <LoadingError text="Ha ocurrido un error cargando las categorías"></LoadingError>
  }
  

  return (
    <>
        <Table columns={short ? columShort : columns} dataSource={categories} showSorterTooltip={false} scroll={{x: true}}
        ></Table>
    </>
  )
}

export default CategoriesTable