import { Button, Typography } from 'antd'
import React, { useState } from 'react'
import AddAdminForm from '../../components/AdminTable/AddAdminForm'
import Admin from '../../components/AdminTable/admin'

function AdminsPage() {
  const [formVisible, setFormVisible] = useState(false)
  return (
    <>
        <Typography.Title level={4}>Administradores de ENS Español</Typography.Title>
        <Admin />
        { formVisible ?
        <>
        <Typography.Title level={5}>Para añadir un nuevo Administrador:</Typography.Title>
        <AddAdminForm></AddAdminForm> 
        </> : 
        <Button type='primary' onClick={() => setFormVisible(true)}>Añadir administrador</Button>
        }
    </>
  )
}

export default AdminsPage