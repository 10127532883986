
import { Layout, Menu, theme } from 'antd';
import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import AdminsPage from '../../pages/Admin/AdminsPage';
import AllCategories from '../../pages/Category/AllCategories';
import DeleteInCategory from '../../pages/Category/DeleteInCategory';
import EditCategory from '../../pages/Category/EditCategory';
import SelectDeleteCategory from '../../pages/Category/SelectDeleteCategory';
import LastConfirmedCategorization from '../../pages/Last/LastCategorization';
import LastApprovedCategorization from '../../pages/Last/LastCategorization';
import PendingInCategory from '../../pages/PendingCategorization/PendingInCategory';
import SelectPendingCategory from '../../pages/PendingCategorization/SelectPendingCategory';
import Admin from '../AdminTable/admin';
import CategoriesTable from '../CategoriesTable/CategoriesTable';
import DomainsTable from '../DomainsTable/DomainsTable';
import RainbowButton from '../RainbowButton/RainbowButton';
import NavigationMenu from './Menu';
const { Header, Content, Footer, Sider } = Layout;




const AppLayout = () => {
 
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <Layout style={{
       minHeight:"100vh"
    }}>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        width={250}
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
        style={{
          
            padding:2,
            background: colorBgContainer,
           
          }}
      >
        <div style={{
            padding: 10,
            marginTop: 20,
            marginLeft: 10,
            
            background: colorBgContainer,
            
           
          }}>
        <RainbowButton />
        </div>
        
        <NavigationMenu/>
       
      </Sider>
      <Layout>
        <Content
          style={{
            margin: '24px 16px 0',
          }}
        >
          <div
            style={{
              padding: 24,
              minHeight: 360,
              background: colorBgContainer,
            }}
          >
            {/*Put childs here */}
            <Routes>
              <Route exact path="/" element={<AllCategories/>}></Route>
              <Route exact path="/admin" element={<AdminsPage/>}></Route>
              <Route exact path="/category" element={<AllCategories/>}></Route>
              <Route exact path="/category/edit" element={<EditCategory/>}></Route>
              <Route exact path="/category/delete/:categoryID" element={<DeleteInCategory/>}></Route>
              <Route exact path="/pendingInCategory" element={<SelectPendingCategory/>}></Route>
              <Route exact path="/deleteInCategory" element={<SelectDeleteCategory/>}></Route>
              <Route exact path="/pending/:categoryID" element={<PendingInCategory/>}></Route>
              <Route exact path="/last/rejected" element={<LastConfirmedCategorization status={{"value": "rejected", "label":"RECHAZADO"}}/>}></Route>
              <Route exact path="/last/approved" element={<LastConfirmedCategorization status={{"value": "approved", "label":"APROBADO"}}/>}></Route>
            </Routes>
            
          </div>


        </Content>
        <Footer
          style={{
            textAlign: 'center',
          }}
        >
          Herramienta de Moderación de ENS Español
        </Footer>
      </Layout>
    </Layout>
  );
};
export default AppLayout;