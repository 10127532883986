import { Button, message, Space, Table } from 'antd';
import React, { useEffect, useState } from 'react'
import { DeleteOutlined } from '@ant-design/icons';
import { useDeleteCategorizationMutation, useDeleteCategoryMutation } from '../../services/ensesp-api';
import SignModal from '../Signature/SignModal';



function DeleteTable({domains, category}) {
  const [modal, setModal] = useState(false)
  const [fieldsToSign, setFieldsToSign] = useState(undefined)
  const [postModeration, result ] = useDeleteCategorizationMutation()
  const [postDeleteCategory, resultCategory  ] = useDeleteCategoryMutation()

  useEffect(() => {
    if (result.isError) {
      message.error("Ha ocurrido un error sometiendo la moderación")
      return
    }
    if (result.isSuccess) {
      if(!result.data  || result.data.length < 1) {
        message.warning("No hemos recibido confirmación sobre el estado de su solicitud")
        return
      }
      if(!result.data[0].Success) {
        message.error("Recibimos su solicitud pero no fue exitosa")
        return
      }
      message.success("Solicitud completada exitosamente")
      setModal(false)
      setTimeout(() => window.location.reload(false), 1000);
      return
    }
  

  
    
  }, [result])

  useEffect(() => {
    console.log(resultCategory)
    if (resultCategory.isError) {
      message.error("Ha ocurrido un error sometiendo la moderación")
      return
    }
    if (resultCategory.isSuccess) {
      message.success("Solicitud completada exitosamente")
      setModal(false)
      setTimeout(() => window.location.reload(false), 1000);
    }
      
  }, [resultCategory])


  const columns = [
    {
      title: 'Dominio',
      dataIndex: 'domain',
      key: 'domain',
      sorter: (a, b) => a.domain.localeCompare(b.domain),
    },
    {
        title: 'Estado',
        dataIndex: 'status',
        key: 'status',
        sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: 'Solicitado por',
      dataIndex: 'requested_by',
      key: 'requested_by',
      sorter: (a, b) => a.requested_by.localeCompare(b.requested_by),
  
      
    },
    {
      title: 'Última actualización',
      dataIndex: 'updatedAtString',
      key: 'updatedAtString',
      sorter: (a, b) => a.updatedAt > b.updatedAt,
    },
    {
        title: 'Autorizado por',
        dataIndex: 'authorized_by',
        key: 'authorized_by',
    },
    {
        title: 'Motivo de moderación',
        dataIndex: 'reason',
        key: 'reason',
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
      <Space size="middle">
        <Button danger  icon={<DeleteOutlined />} onClick={() => buildDeleteFields(record)}>Eliminar</Button>
     </Space>
        
      ),
    },
  ];

  function buildDeleteFields(record) {
    setFieldsToSign([{"category": category, "domain": record.domain, "status": record.status}])
    setModal(true)
  }

  function buildFullCategoryDelete() {
    setFieldsToSign({"delete_entire_category" : category})
    setModal(true)
  }

  function onSubmit({address, signature}) {
    console.log(fieldsToSign, category,fieldsToSign === category )
    if(fieldsToSign.delete_entire_category) {
      postDeleteCategory({"address": address, "signature": signature, "body": fieldsToSign, "category": category})
      return
    }
    postModeration({"address": address, "signature": signature, "body": fieldsToSign})
  }



  return (
    <>
    <Table showSorterTooltip={false} dataSource={domains} columns={columns} scroll={{x: true}}/>
    <Button type="primary" danger onClick={() => buildFullCategoryDelete()}> Borrar categoría completamente</Button>
      <SignModal title={"Autoriza la operación"}  open={modal} setOpen={setModal} fields={fieldsToSign} body={<div>Las categorizaciones eliminadas dejarán de existir en nuestra base de datos</div>} onSubmit={onSubmit} />

    </>
  )
}

export default DeleteTable