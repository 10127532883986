import { Menu } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom';

import {TableOutlined, TeamOutlined, QuestionOutlined, ExceptionOutlined, FileSearchOutlined, CloudSyncOutlined,CheckOutlined, CloseOutlined, ToolOutlined, DeleteOutlined } from '@ant-design/icons';

const items = [
    {
        label: 'Administradores',
        key: 'admin',
        icon: <TeamOutlined /> ,
        
      },
    {
      label: 'Categorías',
      key: 'category-menu',
      icon: <TableOutlined /> ,
      children: [
        {
          label: 'Ver Todas',
          key: 'category',
          icon: <TableOutlined />,
        },
        {
          label: 'Gestionar',
          key: 'category/edit',
          icon: <ToolOutlined />,
        },
        {
          label: 'Eliminar',
          key: 'deleteInCategory',
          icon: <DeleteOutlined />,
        },
       
    ]
    },
    {
        label: 'Pendientes',
        key: 'pending-categories',
        icon: <QuestionOutlined /> ,
        children: [
            {
              label: 'Todas',
              key: 'pending/all',
              icon: <ExceptionOutlined />,
            },
            {
                label: 'En categoría',
                key: 'pendingInCategory',
                icon: <FileSearchOutlined  />,
            },
           
        ]
      },
      {
        label: 'Últimas',
        key: 'last',
        icon: <CloudSyncOutlined /> ,
        children: [
            {
                label: 'Aprobadas',
                key: 'last/approved',
                icon: <CheckOutlined />,
            },
            {
              label: 'Rechazadas',
              key: 'last/rejected',
              icon:<CloseOutlined />,
            },
            
           
        ]
      },
      
    
  ]

function  NavigationMenu() {
    const navigate = useNavigate()

  const onClick = (e) => {
   navigate(`/${e.key}`);
    
  };
  return (
    <Menu mode="inline" onClick={onClick} defaultSelectedKeys={['1']} items={items}
    />
  )
}

export default NavigationMenu