import { Typography } from 'antd'
import React from 'react'
import IsLoadingMessage from '../../components/ErrorDisplay/IsLoading'
import LoadingError from '../../components/ErrorDisplay/LoadingError'
import LastDomainTable from '../../components/LastDomainsTable/LastDomainTable'
import {  useLastConfirmedCategorizationQuery } from '../../services/ensesp-api'

function LastConfirmedCategorization({status}) {
    const {data: domains, isLoading, isError , isSuccess} = useLastConfirmedCategorizationQuery({"status":status.value})
    if (isLoading) {
        return <IsLoadingMessage text={"Estamos cargando las últimas categorizaciones"}></IsLoadingMessage>
    }
    if (isError) {
       return  <LoadingError text={"Ha ocurrido un error "}></LoadingError>
    }
    if(isSuccess && (!domains || domains.length <1 )) {
        return <LoadingError text={"No se encontraron resultados para mostrar"}></LoadingError>
    }
    const data = domains?.map(element => {
        const date = new Date(element.updated_at)
        const options = {
           year: 'numeric', month: 'numeric', day: 'numeric',hour: 'numeric', minute: 'numeric',
        };
        const dateString = new Intl.DateTimeFormat('es-AR', options).format(date)
        return {
          "domain" : element.domain,
          "category" : element.category,
          "requested_by" : element.requested_by,
          "authorized_by" : element.authorized_by,
          "status": element.status,
          "updatedAtString" : dateString,
          "updatedAt": date,
          "status" : status.label,
          "reason": status.reason
        }
      })
  return (
    <>
     <Typography.Title level={4}>Últimas categorizaciones moderadas</Typography.Title>
        <LastDomainTable domains={data}/>
    </>
  )
}

export default LastConfirmedCategorization