import {createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseUrl = "https://apiv2.xn--ensespaol-r6a.com"

//const baseUrl = "http://localhost:8080"


export const ensesp = createApi({
    reducerPath: 'ensesp',
    baseQuery: fetchBaseQuery({baseUrl}),
    endpoints: (builder) => ({
        categories: builder.query({
            query: ()=> ({
                url: "/category",
                method: "GET",
                headers: {
                    "Content-Type" : "application/json"
                }
            }), 
            
        }),
        pendingCategorization: builder.query({
            query: (category)=> ({
                url: `/categorization/pending/${category}`,
                method: "GET",
                headers: {
                    "Content-Type" : "application/json"
                }
            }), 
            
        }),
        getAdmins: builder.query({
            query: ()=> ({
                url: `/admin`,
                method: "GET",
                headers: {
                    "Content-Type" : "application/json"
                }
            }), 
            
        }),
        lastConfirmedCategorization: builder.query({
            query: ({status})=> ({
                url: `/categorization/last/${status}`,
                method: "GET",
                headers: {
                    "Content-Type" : "application/json"
                }
            }), 
            
        }),
        categorizationInCategory: builder.query({
            query: ({category})=> ({
                url: `/categorization/confirmed/${category}`,
                method: "GET",
                headers: {
                    "Content-Type" : "application/json"
                }
            }), 
            
        }),
        approveCategorizationBatch: builder.mutation({
            query: (props) => ({
                    url: `/categorization/batch/${props.approve}`,
                    method: "POST",
                    body: props.body,
                    headers: {
                        "Content-Type" : "application/json",
                        "Address": props.address,
                        "Signature": props.signature,
                    }
                }),
        }),
        createCategory: builder.mutation({
            query: (props) => ({
                    url: `/category/create/${props.category}`,
                    method: "POST",
                    body: props.body,
                    headers: {
                        "Content-Type" : "application/json",
                        "Address": props.address,
                        "Signature": props.signature,
                    }
                }),
        }),
        deleteCategorization: builder.mutation({
            query: (props) => ({
                    url: `/categorization/batch/delete`,
                    method: "POST",
                    body: props.body,
                    headers: {
                        "Content-Type" : "application/json",
                        "Address": props.address,
                        "Signature": props.signature,
                    }
                }),
        }),
        deleteCategory: builder.mutation({
            query: (props) => ({
                    url: `/category/delete/${props.category}`,
                    method: "POST",
                    body: props.body,
                    headers: {
                        "Content-Type" : "application/json",
                        "Address": props.address,
                        "Signature": props.signature,
                    }
                }),
        }),
        createAdmin: builder.mutation({
            query: (props) => ({
                    url: `/admin`,
                    method: "POST",
                    body: props.body,
                    headers: {
                        "Content-Type" : "application/json",
                        "Address": props.address,
                        "Signature": props.signature,
                    }
                }),
        }),
    })
    
})

export const {
    useCategoriesQuery,
    usePendingCategorizationQuery,
    useApproveCategorizationBatchMutation,
    useCreateCategoryMutation,
    useLastConfirmedCategorizationQuery,
    useGetAdminsQuery,
    useCategorizationInCategoryQuery,
    useDeleteCategorizationMutation,
    useDeleteCategoryMutation,
    useCreateAdminMutation,
} = ensesp;