import { Typography } from 'antd'
import React from 'react'
import { useParams } from 'react-router-dom'
import DomainsTable from '../../components/DomainsTable/DomainsTable'
import LoadingError from '../../components/ErrorDisplay/LoadingError'

function PendingInCategory() {
  const {categoryID} = useParams()
  if (!categoryID) {
    return <LoadingError text={"No se ha encontrado la categoría"}/>
  }
  return (
    <>
      <Typography.Title level={4}>{categoryID=='all' ? "Todas las categorizaciones pendientes: " : `Categorizaciones pendientes en ${categoryID}: `}</Typography.Title>
      <DomainsTable category={categoryID === 'all' ? ''  : categoryID}></DomainsTable>
    </>
  )
}

export default PendingInCategory