import { Select } from 'antd';
import React from 'react'
import { useCategoriesQuery } from '../../services/ensesp-api';
import IsLoading from '../ErrorDisplay/IsLoading';
import LoadingError from '../ErrorDisplay/LoadingError';

const omit = "español"

function SelectCategory({onSelect}) {
    const {data: categories, isLoading, isError} = useCategoriesQuery();
    if (isLoading) {
      return <IsLoading text={"Cargando categorías"}/>
    }
    if (isError) {
      return <LoadingError text={"Ha ocurrido un error cargando las categorías"}/>
    }
    if (!categories || categories?.length < 1) {
      return <LoadingError text={"No hemos encontrado categorías"}/>
    }
    
    
    const options = categories.filter(categorie => categorie.category != omit).map((categorie) => {
      return {
        value: categorie.category,
        label: categorie.category
    }
      
    })
    console.log(options)
    if (!options || options.length < 1) {
      return <LoadingError text={"No hemos encontrado categorías accesibles"}/>
      
    }

    return (
      <>
       
       <Select
       style={{minWidth: "300px"}}
        showSearch
        placeholder="Selecciona una categoría para continuar"
        optionFilterProp="children"
        onSelect={onSelect}
        filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        options={options}
        />
       
      </>
      
    )
}

export default SelectCategory