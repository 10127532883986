import { AutoComplete, Button, Form, Input, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useCategoriesQuery, useCreateCategoryMutation } from '../../services/ensesp-api';
import CategoriesTable from '../CategoriesTable/CategoriesTable';
import SignModal from '../Signature/SignModal';
const { TextArea } = Input;

const REQUIRED =  {
  label: "Español (reconocimiento)",
  value: `español`
}

function EditCategoryFrom() {
    const {data: categories, isLoading, isError} = useCategoriesQuery();
    const [postModeration, result, error  ] = useCreateCategoryMutation()

    const [form] = Form.useForm();
    const [modal, setModal] = useState(false)
    const [fieldsToSign, setFieldsToSign] = useState({})

    useEffect(() => {
        console.log(result)
        if (result.isError) {
          message.error("Ha ocurrido un error sometiendo la moderación")
          return
        }
        if (result.isSuccess) {
          if(!result.data  || result.data.length < 1) {
            message.warning("No hemos recibido confirmación sobre el estado de su solicitud")
            return
          }
          if(!result.data[0].Success) {
            message.error("Recibimos su solicitud pero no fue exitosa")
            return
          }
          message.success("Solicitud completada exitosamente")
          setModal(false)
          setTimeout(() => window.location.reload(false), 1000);
          return
        }
      
    
      
        
      }, [result])
    

    function deleteETHAndEmptyDomains(values) {
        const response = []
         for( let i =0; i < values.length; i++) {
            const st = values[i].replace(/ /g,'').toLowerCase()
            if (st.length < 3) {
                continue
            }
            if (st.endsWith(".eth")) {
                if (st.length < 7) {
                    continue
                }
                response.push(st.slice(0,st.length - 4))
                continue
            }
            response.push(st)
         }
         return response
      }

      

    const options = categories?.map(category => {
        return {
          label: `${category.category} (${category.points} pts)`,
          value: `${category.category}`
        }
      })

      //const options = opts && opts.length > 0 ? [REQUIRED, ...opts] : []
      

      const onReset = () => {
        form.resetFields();
      };

      function onSubmit({address, signature}) {
        postModeration({"address": address, "signature": signature, "body": fieldsToSign, "category": fieldsToSign.category})
      }

      const onConfirm = () => {
        const st = form.getFieldValue("field-words") 
        const cat = form.getFieldValue("field-name") 
        const short = form.getFieldValue("field-shortName") ? form.getFieldValue("field-shortName") : ""
        const points = form.getFieldValue("field-points") ? form.getFieldValue("field-points") : 0
        const group = form.getFieldValue("field-group") ? form.getFieldValue("field-group") : ""
        if(!cat) {

           message.warning("Debe especificar una categoría")
            return
        }
        console.log(group)
        let filtered = [];
        if(st) {
        const arr = st.split("\n")
        filtered = deleteETHAndEmptyDomains(arr)
        }
        const queryObj = {
            category: cat,
            domains: filtered,
            short: short,
            points: +points,
            group: group,
        }
        setFieldsToSign(queryObj)
        setModal(true)

      };
      

  return (
    <>
    <Form
    form={form}
    size="default"
    layout="vertical"
    
    >
        <Form.Item label="Nombre de la categoría" name={`field-name`}>
        <AutoComplete placeholder='Si no existe, se creará una nueva categoría'
        options={options}
        filterOption={(inputValue, option) =>
            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
        style={{width:300}}
        />
        </Form.Item>
        <Form.Item label="Nombre reducido (para imagen)" name={`field-shortName`}>
            <Input placeholder='(máximo 7 caracteres)' maxLength={7}  style={{width:300}}/>
        </Form.Item>
        <Form.Item label="Puntos de relevancia (orden más relevante)" name={`field-points`}>
            <Input type='number' placeholder='A más puntos mayor relevancia' style={{width:250}}/>
        </Form.Item>
        <Form.Item label="Grupo o Relación" name={`field-group`}>
            <Input placeholder='¿A qué grupo pertenece?'   style={{width:300}}/>
        </Form.Item>
        <Form.Item label="Dominios " name={`field-words`}>
            <TextArea style={{"maxHeight": "300px", width:300}} autoSize={true} placeholder={"Separa las palabras en una nueva línea sin .eth, ejemplo: \nperro\ngato\ntortuga\n..."}/>
         </Form.Item>
         <Form.Item>
         <Button type='reset' style={{margin:10,marginLeft:50 }} onClick={onReset}>
            Limpiar
        </Button>
        <Button type='primary'  onClick={onConfirm} htmlType="submit">
            Confirmar
        </Button>
        </Form.Item>

    </Form>
    
    <SignModal onSubmit={onSubmit} title={"Confirma los cambios en la categoría"} open={modal} setOpen={setModal} fields={fieldsToSign} body={<div>Asegúrate que las modificaciones sean las correctas</div>}/>
    </>
 
  )
}

export default EditCategoryFrom